<template>
	<div id="trainOperation">
		<div class="manage">
      <div class="manageTitle">管理</div>
      <div class="serviseBox">
        <div class="serviseOne" @click="toTrafficCharge">
          <img src="../../assets/img/trafficCharge.png" class="serviceImg"/>
          <div class="serviceText">委托车务</div>
        </div>
        <div class="serviseOne" @click="toServiceDescription">
          <img src="../../assets/img/serviceDescription.png" class="serviceImg"/>
          <div class="serviceText">服务介绍</div>
        </div>
        <div class="serviseOne" @click="toCustomerConsultation">
          <img src="../../assets/img/customerConsultation.png" class="serviceImg"/>
          <div class="serviceText">客服咨询</div>
        </div>
        <div class="serviseOne" @click="toPastServices">
          <img src="../../assets/img/pastServices.png" class="serviceImg"/>
          <div class="serviceText">过往服务</div>
        </div>
      </div>
      <!-- <div class="serviseOne" style="margin-left: 0.24rem;" @click="toServicePayment">
        <img src="../../assets/img/servicePayment.png" class="serviceImg"/>
        <div class="serviceText">服务缴费</div>
      </div> -->
    </div>
	</div>
</template>

<script>
import { validApp, getUrlParam, setCookie, getCookie, delCookie,domainUrl } from '../../lib/utils';
import { Dialog, Toast } from 'vant';
import { wechatLogin, updateWechatToken } from '@/server/request'
let CWTOKEN
export default {
	data() {
		return {
      code: getUrlParam('code') ||''
		}
	},
	created() {
    document.title = '车务服务'
    CWTOKEN = getCookie('CWTOKEN')
		if (!CWTOKEN || CWTOKEN === 'undefined') {
      //没有授权,没有获取code
      if(!this.code) {
        let uiCode = encodeURIComponent(domainUrl+'/trainOperation');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        wechatLogin({data:{code:this.code}}).then(res=>{
          setCookie('CWTOKEN',res.data.token);
        })
      }
    }else{
      updateWechatToken({data:{token:CWTOKEN}}).then(res=>{
        setCookie('CWTOKEN',res.data.token);
      })
    }
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
    toTrafficCharge(){
      this.$router.push({path: '/trafficCharge'})
    },
    toServiceDescription(){
      this.$router.push({path: '/serviceDescription'})
    },
    toCustomerConsultation(){
      this.$router.push({path: '/customerConsultation'})
    },
    toPastServices(){
      this.$router.push({path: '/pastServices'})
    },
    toServicePayment(){
      this.$router.push({path: '/servicePayment'})
    }
  },
};
</script>

<style>
html,body{
  height: 100%;
}
#app{
  height: 100%;
}
#trainOperation{
  background-color: #F5F5F5;
  padding-top: 0.3rem;
}
.manage{
  background-color: #ffffff;
}
.manageTitle{
  padding: 0.18rem 0.2rem;
  font-size: 0.36rem;
  color: #333333;
  font-weight: bold;
}
.serviseBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.4rem 0.24rem;
}
.serviseOne{
  width: 1.3rem;
  text-align: center;
}
.serviceImg{
  width: 0.68rem;
  height: 0.68rem;
}
.serviceText{
  font-size: 0.26rem;
  color: #333333;
  margin-top: 0.16rem;
}
</style>
